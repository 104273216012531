document.addEventListener("DOMContentLoaded", () => {
    let $toggle = document.querySelector(".-js-wishlist-toggle")
    let $title = document.querySelector(".-js-wishlist-title")

    if (!$toggle || !$title || $toggle.classList.contains("-js-modal-link")) {
        return
    }

    let productId = $toggle.dataset.product

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        toggleProduct(productId).then((res) => {
            if (res.error) {
                return alert(wishlist.error)
            }

            $title.textContent = res.status ? wishlist.remove : wishlist.add

            if (res.status) {
                $toggle.classList.add("-active")
            } else {
                $toggle.classList.remove("-active")
            }
        })
    })
})

let toggleProduct = (productId) => {
    return fetch(wishlist.ajaxUrl, {
        method: "POST",
        headers: {
            "Cache-Control": "no-cache",
        },
        body: new URLSearchParams({
            action: "wishlist_toggle_product",
            product: productId,
        }),
    })
        .then((response) => response.json())
        .catch((err) => console.log(err))
}
